import { render, staticRenderFns } from "./AccountSettingsView.vue?vue&type=template&id=a5cdd962&scoped=true"
import script from "./AccountSettingsView.vue?vue&type=script&lang=ts"
export * from "./AccountSettingsView.vue?vue&type=script&lang=ts"
import style0 from "./AccountSettingsView.vue?vue&type=style&index=0&id=a5cdd962&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5cdd962",
  null
  
)

export default component.exports